'use client';
import { useCallback, useRef, useState, useEffect } from 'react';
import '../styles/App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import nuwAILogo from '../assets/NuwAI@2x.png';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { ReactComponent as SettingsIcon } from '../assets/settings.svg'
import { ReactComponent as UploadIcon } from '../assets/upload.svg'
import { ReactComponent as SaveIcon } from '../assets/save.svg'
import { ReactComponent as TrashIcon } from '../assets/trash-alt.svg'

import { ReactComponent as DownloadIcon } from '../assets/file-pdf.svg'
import { ReactComponent as ArrowRight } from '../assets/arrow-alt-circle-right.svg'
import { ReactComponent as ArrowLeft } from '../assets/arrow-alt-circle-left.svg'
import { ReactComponent as Close } from '../assets/times-circle.svg'
import { ReactComponent as EnterInputIcon } from '../assets/enter-input.svg'
import SignInSignOutButton from "../components/ui/signInLogoutButton";
import useAutosizeTextArea from "../useAutosizeTextArea";

import API from "../components/bot/chatBot";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"

import BotMessage from "../components/bot/botMessage";
import UserMessage from "../components/bot/userMessage";

import Input from "../components/bot/userInput";

import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleSwitch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';

import LoginScreen from "./login"

import apiConfig from "../config/api";

import { loginRequest } from "../config/msalConfig";


import { callMsGraph, callMsGraphTenant } from "../utils/MsGraphApiCall";

import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";

import { BlobServiceClient, StorageSharedKeyCredential } from "@azure/storage-blob"

import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';
import ReactGA from "react-ga4";

import backend_api from "../utils/BackendApiCaller"

import { v4 as uuidv4 } from 'uuid';

const containerName = `${apiConfig.blobStore[process.env.REACT_APP_BLOB_STORE]}`

const blobServiceClient = new BlobServiceClient(`https://nuwaiato.blob.core.windows.net/?sv=2022-11-02&ss=b&srt=sco&sp=rl&se=2024-12-30T17:40:35Z&st=2024-08-12T10:40:35Z&spr=https&sig=nWdsj%2B6s9AnzNfRxXI2kHfI%2B3gb%2FeG7IxtMyUZ93IgU%3D`);

const SettingSwitch = styled((props) => (
  <ToggleSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#F29E49',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E0E0E0',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

let nextId = 0;

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs`;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();

const resizeObserverOptions = {}
const maxWidth = 750

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/"
}

export function Home() {
  const [inputValue, setInputValue] = useState("")
  const [settingsMenuPosition, setSettingsMenuPosition] = useState("-600px")
  const [settingsMenuWidth, setSettingsMenuWidth] = useState("0px")
  const [inputHeight, setInputHeight] = useState("65px")
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null)
  const [fileUploaded, setFileUploaded] = useState(false)
  const [file, setFile] = useState("")
  const [blobName, setBlobName] = useState("");

  const [blobAzure, setBlobAzure] = useState("")
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [containerWidth, setContainerWidth] = useState()
  const [viewPDF, setViewPDF] = useState("")
  const [blobTester, setBlobTester] = useState(false)

  const [aiModel_nuwai, setaiModel_nuwai] = useState(false);
  const [aiModel_gpt, setaiModel_gpt] = useState(true);
  const [aiModel_gemini, setaiModel_gemini] = useState(false);
  const [active_aiModel, setActiveAiModel] = useState("gpt");

  const [aiTone_simple, setTone_simple] = useState(false);
  const [aiTone_advisor, setTone_advisor] = useState(false);
  const [active_aiTone, setActiveAiTone] = useState("");

  const divRef = useRef(null);

  const [snackText, setSnackText] = useState("")
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    vertical: 'bottom',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;
  const [apiType, setApiType] = useState("normal")
  const [enableReferences, setReferences] = useState(false);
  const bottomChat = useRef(null)
  const [count, setCount] = useState(1)
  const [aiHistory, setAiHistory] = useState([{
    id: 0,
    user_id: 0.0,
    model: "nuwai",
    question: "initial",
    references: false,
    response: "Welcome to NuwAI",
    sources: "",
    latest: false,
    tone: "",
    type: "status",
    inputTokens: 0,
    outputTokens: 0,
    totalTokens: 0
  }])

  const [showProfile, setShowProfile] = useState(false);
  const { instance, inProgress, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null)
  const [graphTenantData, setGraphTenantData] = useState(null)
  const [apiData, setApiData] = useState(null);
  const [statusResponse, setStatusResponse] = useState(false)
  const [sessionUID, setSessionUID] = useState("")

  useAutosizeTextArea(textAreaRef.current, inputValue);

  const openSettings = evt => {
    if (settingsMenuPosition == "-600px") {
      setSettingsMenuPosition("40px")
      setSettingsMenuWidth("265px")
    } else {
      setSettingsMenuPosition("-600px")
      setSettingsMenuWidth("0px")
    }
  }

  const handleChange = evt => {
    const val = evt.target?.value
    setInputValue(val)
  }

  const handleFileChange = async e => {
    e.preventDefault();
    if (e.target.files) {
      setFileUploaded(true);
      const formData = new FormData();
      var file_names = ""
      for (var x = 0; x < e.target.files.length; x++) {
        console.log("File " + x + " " + e.target.files[x].name)
        formData.append('file', e.target.files[x], e.target.files[x].name);

        if ((x + 1) == e.target.files.length) {
          file_names = file_names + e.target.files[x].name;
        } else {
          file_names = file_names + e.target.files[x].name + ", ";
        }

      }
      setFile(file_names)
      var response = ""

      const accessTokenRequestAPI = {
        scopes: [`${process.env.REACT_APP_ENTRA_APP_API}`],
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(accessTokenRequestAPI)
        .then(async (accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          console.log(accessToken)
          // Call your API with token
          response = await API.UploadFiles(formData, accessToken, graphTenantData, sessionUID);

          setAiHistory([...aiHistory, {
            id: count,
            user_id: `${count}.${count}`,
            session_id: sessionUID,
            model: "",
            question: "",
            references: "",
            response: response,
            sources: "",
            latest: true,
            tone: "",
            type: "status",
            inputTokens: 0,
            outputTokens: 0,
            totalTokens: 0
          }])
          setCount(count + 1);
          // callApi(accessToken).then((response) => {
          //   setApiData(response);
          // });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequestAPI);
          }
          console.log(error);
        });
    }
  }

  const deleteFile = e => {
    e.preventDefault();

    const accessTokenRequestAPI = {
      scopes: [`${process.env.REACT_APP_ENTRA_APP_API}`],
      account: accounts[0],
    };
    var response = ""

    instance
      .acquireTokenSilent(accessTokenRequestAPI)
      .then(async (accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        console.log(accessToken)
        // Call your API with token
        response = await backend_api.deleteFile(accessToken, graphTenantData, sessionUID);

        setFile("")
        setFileUploaded(false);
        setAiHistory([...aiHistory, {
          id: count,
          session_id: sessionUID,
          model: "",
          question: "",
          references: "",
          response: "Uploaded files have been deleted.",
          sources: "",
          latest: true,
          tone: "",
          type: "status",
          inputTokens: 0,
          outputTokens: 0,
          totalTokens: 0
        }])
        setCount(count + 1);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequestAPI);
        }
        console.log(error);
      });
  }

  const updateAiResponse = async (responseObject, id) => {
    console.log("Update AI Response", responseObject)
    setAiHistory(aiHistory => aiHistory.map(option => option.id === id
      ? { ...option, response: responseObject.response, sources: responseObject.sources, totalTokens: responseObject.total_tokens, inputTokens: responseObject.input_tokens, outputTokens: responseObject.output_tokens }
      : option
    ));
  }

  const downloadPDF = async () => {
    const blobUrl = window.URL.createObjectURL(blobAzure);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${blobName}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
  }

  const newAiHistory = async (question) => {
    console.log("Count is at ", count);
    if (question != "" || question != null) {
      setAiHistory([...aiHistory, {
        id: count,
        user_id: `${count}.${count}`,
        session_id: sessionUID,
        model: active_aiModel,
        question: question,
        references: enableReferences,
        response: "",
        sources: "",
        latest: true,
        tone: active_aiTone,
        type: "message",
        inputTokens: 0,
        outputTokens: 0,
        totalTokens: 0
      }])
      setCount(count + 1);
      if (count != 1) {
        var num = count - 1;
        setAiHistory(aiHistory => aiHistory.map(option => option.id === num
          ? { ...option, latest: false }
          : option
        ));
        divRef.current.scrollIntoView({ block: "end", behavior: "smooth" })
      }
      divRef.current.scrollIntoView({ behavior: 'smooth' })
      console.log("Count is now at ", count);
    } else {
      handleClick("Invalid question or question format, Please try again.");
    }

  }

  const handleClick = (text) => {
    setSnackText(text)
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setOpen(false);
    }
  };

  const aiModel_change = (event) => {
    var model_name = event.target.name;
    var checkedState = event.target.checked;

    console.log(model_name)
    console.log(checkedState)
    if (model_name == "nuwai") {
      if (checkedState == false && aiModel_gpt == false && aiModel_gemini == false) {
        console.log("Not allowed")
      } else {
        setaiModel_nuwai(checkedState);
        setaiModel_gemini(false);
        setaiModel_gpt(false);
        setActiveAiModel(model_name)
      }
    } else if (model_name == "gpt") {
      if (checkedState == false && aiModel_nuwai == false && aiModel_gemini == false) {
        console.log("Not allowed")
      } else {
        setaiModel_nuwai(false);
        setaiModel_gpt(checkedState);
        setaiModel_gemini(false);
        setActiveAiModel(model_name)
      }
    } else if (model_name == "gemini") {
      if (checkedState == false && aiModel_gpt == false && aiModel_nuwai == false) {
        console.log("Not allowed")
      } else {
        setaiModel_nuwai(false);
        setaiModel_gpt(false);
        setaiModel_gemini(checkedState);
        setActiveAiModel(model_name)
      }
    }
  };

  const aiTone_change = (event) => {
    var tone_name = event.target.name;
    var checkedState = event.target.checked;

    if (tone_name == "simple") {
      setTone_simple(checkedState);
      setTone_advisor(false);
      if (checkedState) {
        setApiType("simplify")
        setActiveAiTone(tone_name)
      } else {
        setApiType("normal")
        setActiveAiTone('')
      }
    } else if (tone_name == "tax-advisor") {

      setTone_simple(false);
      setTone_advisor(checkedState);

      if (checkedState) {
        setApiType("tax-advisor")
        setActiveAiTone(tone_name)
      } else {
        setApiType("normal")
        setActiveAiTone('')
      }
    }
  };

  const otherSettings_change = (event) => {
    var setting_name = event.target.name;
    var checkedState = event.target.checked;

    if (setting_name == "references") {
      setReferences(checkedState);
    }
  };

  const AlwaysScrollToBottom = () => {
    console.log("Scrolling to bottom");
    const elementRef = useRef();
    console.log("ElementRef ", elementRef);
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const ApiStatusCheck = async () => {
    var api_response = await backend_api.apiStatus()
    setStatusResponse(api_response.Status)
    return api_response
  }


  useEffect(() => {
    console.log('rending home')
    // divRef.current?.scrollIntoView({ behavior: 'smooth' })
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: "/",
    //   title: "Home"
    // })

    console.log(process.env.REACT_APP_ENTRA_APP_API)

    const accessTokenRequestAPI = {
      scopes: [`${process.env.REACT_APP_ENTRA_APP_API}`],
      account: accounts[0],
    };

    ApiStatusCheck()

    if (statusResponse) {
      console.log("Smooth Scrolling")
      // divRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    if (statusResponse && !graphData && graphData == null && inProgress === InteractionStatus.None) {
      console.log("Calling Graph API")
      callMsGraph()
        .then(response => {
          var userDetails = {
            "jobTitle": `${response.jobTitle}`,
            "mobilePhone": `${response.mobilePhone}`,
          }

          setGraphData(response)
          callMsGraphTenant()
            .then(response => {
              var tenantDetails = {
                "tid": response.value[0].id,
                "tdn": response.value[0].displayName
              }

              setGraphTenantData(tenantDetails)
              // if (!apiData && inProgress === InteractionStatus.None) {
              instance
                .acquireTokenSilent(accessTokenRequestAPI)
                .then((accessTokenResponse) => {
                  // Acquire token silent success
                  let accessToken = accessTokenResponse.accessToken;
                  console.log(accessToken)
                  // Call your API with token
                  var sessionUID = uuidv4();
                  console.log(sessionUID)
                  setSessionUID(sessionUID)
                  backend_api.addOrganisation(accessToken, tenantDetails, userDetails, sessionUID)
                  // callApi(accessToken).then((response) => {
                  //   setApiData(response);
                  // });
                })
                .catch((error) => {
                  if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequestAPI);
                  }
                  console.log(error);
                });
              // }
            })
            .catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect({
                  ...loginRequest,
                  account: instance.getActiveAccount()
                });
              }
            })
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount()
            });
          }
        });
    }
  }, [inProgress, accounts, graphData, instance, statusResponse]);

  const closeBlob = async (event) => {
    setViewPDF("")
    setBlobAzure("")
    setBlobTester(false);
  }

  const viewProfileData = async (event) => {
    var show_profile = event.target.name;
    var checkedState = event.target.checked;
    callMsGraphTenant().then(response => setGraphTenantData(response.value)).catch((e) => {
      console.log("Hello", e)
    });
    console.log("Graph Tenant", graphTenantData)

    if (show_profile == "userProfile") {
      if (checkedState) {
        setShowProfile(checkedState);
        console.log(graphData)
        console.log(graphData.displayName)
        console.log(graphData.givenName)
        console.log(graphData.surname)
        console.log(graphData.jobTitle)
        console.log(graphData.mail)
        console.log(graphData.businessPhones[0])
        console.log(graphData.mobilePhone)
      } else {
        setShowProfile(checkedState);
      }
    }
  }

  const getBlob = async (fileName, location) => {
    setViewPDF("viewing-pdf")

    setBlobName(fileName)

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(location);
    const downloadBlockBlobResponse = await blobClient.download();

    console.log(downloadBlockBlobResponse)

    const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

    async function blobToString(blob) {
      const fileReader = new FileReader();
      return new Promise((resolve, reject) => {
        fileReader.onloadend = (ev) => {
          resolve(ev.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsText(blob);
      });
    }

    const newBlob = new Blob([await downloadBlockBlobResponse.blobBody]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    setBlobAzure(newBlob)
  }


  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages)
  }

  const onResize = useCallback(entries => {
    const [entry] = entries

    if (entry) {
      setContainerWidth(entry.contentRect.width)
    }
  }, [])

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  }

  const goToPreviousPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  }

  return (
    <>
      <AuthenticatedTemplate>
        {statusResponse ?
          <>
            <div className={`main-ai-container ${viewPDF}`}>
              <div className="ai-header">
                <img src={nuwAILogo} alt="NuwAI" className="ai-logo" />
              </div>
              <div className="ai-header-divider"></div>
              <div className="ai-center">
                <div className="ai-chat-window">
                  {aiHistory.map(history => (
                    <>
                      {history.question == "initial" || history.type == "status" ? <></> :
                        <UserMessage key={history.user_id} text={history.question} />}
                      {history.type != "status" ?
                        history.id == (count - 1) ?
                          <BotMessage
                            history={history}
                            key={history.id}
                            updateAiResponse={updateAiResponse}
                            downloadPDF={downloadPDF}
                            getBlob={getBlob}
                            fetchMessage={async (accessToken, tenantData, chatData) => await API.GetChatbotResponse(apiType, history.question, history.model, history.tone, history.references, accessToken, tenantData, chatData)}
                          /> : <BotMessage
                            history={history}
                            updateAiResponse={updateAiResponse}
                            downloadPDF={downloadPDF}
                            getBlob={getBlob}
                            key={history.id}
                          />
                        : <BotMessage
                          history={history}
                          updateAiResponse={updateAiResponse}
                          downloadPDF={downloadPDF}
                          getBlob={getBlob}
                          key={history.id}
                        />}

                    </>))}
                  <div id="bottomChat" className="bottomDiv" ref={divRef} />
                </div>
                <div className="ai-settings-window" style={{ width: settingsMenuWidth }}>
                  <div className="ai-settings-inner" style={{ right: settingsMenuPosition }}>
                    <h2 className="ai-settings-main-heading">
                      Settings
                    </h2>
                    <h3 className="ai-settings-headings">
                      Model
                    </h3>
                    <FormGroup className="ai-settings-modelGroup">
                      <FormControlLabel className="ai-settings-label" control={
                        <SettingSwitch
                          size="small"
                          checked={aiModel_nuwai}
                          onChange={aiModel_change}
                          name="nuwai"
                        />}
                        label="Anthropic"
                      />
                      <FormControlLabel className="ai-settings-label" control={
                        <SettingSwitch
                          defaultChecked
                          size="small"
                          checked={aiModel_gpt}
                          onChange={aiModel_change}
                          name="gpt"
                        />}
                        label="GPT"
                      />
                      <FormControlLabel className="ai-settings-label" control={
                        <SettingSwitch
                          size="small"
                          checked={aiModel_gemini}
                          onChange={aiModel_change}
                          name="gemini"
                        />}
                        label="Gemini"
                      />
                    </FormGroup>
                    <h3 className="ai-settings-headings">
                      Tone
                    </h3>
                    <FormGroup className="ai-settings-modelGroup">
                      <FormControlLabel className="ai-settings-label" control={
                        <SettingSwitch
                          defaultChecked
                          size="small"
                          checked={aiTone_simple}
                          onChange={aiTone_change}
                          name="simple"
                        />}
                        label="Simple"
                      />
                      <FormControlLabel className="ai-settings-label" control={
                        <SettingSwitch
                          size="small"
                          checked={aiTone_advisor}
                          onChange={aiTone_change}
                          name="tax-advisor"
                        />}
                        label="Detailed"
                      />
                    </FormGroup>
                    <h3 className="ai-settings-headings">
                      Dev
                    </h3>
                    <FormGroup className="ai-settings-modelGroup">
                      <FormControlLabel className="ai-settings-label" control={
                        <SettingSwitch
                          defaultChecked
                          size="small"
                          checked={showProfile}
                          onChange={viewProfileData}
                          name="userProfile"
                        />}
                        label="User Profile"
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className={`ai-user-container`}>
                <Input onSend={newAiHistory} />
                <div className="ai-controls-box">
                  {/* <img src={saveIcon} alt="Save" className="ai-save" /> */}
                  {fileUploaded ? (
                    <Tooltip title="Delete uploaded files">
                      <TrashIcon alt="Trash" className="ai-upload" onClick={deleteFile} />
                    </Tooltip>
                  ) : (
                    <>
                      <input id='input-file' multiple type="file" onChange={handleFileChange} />
                      <label for='input-file'><Tooltip title="Upload files"><UploadIcon alt="Upload" className="ai-upload" /></Tooltip></label>
                    </>
                  )}
                  <Tooltip title="Modify AI Settings">
                    <SettingsIcon alt="Settings" className="ai-settings" onClick={openSettings} />
                  </Tooltip>
                </div>
                <div class="ai-uploaded-files">
                  <p>
                    {file}
                  </p>
                </div>
              </div>
            </div>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              onClose={handleClose}
              autoHideDuration={5000}
              key={vertical + horizontal}
              message={snackText}
            />
            <div className={`pdf-ai-container ${viewPDF}`}>
              <nav className="ai-pdf-menu">
                <div className="ai-pdf-menu-heading">
                  <h1>
                    {blobName.substring(0, blobName.length - 4)}
                  </h1>
                </div>
                <div className="ai-pdf-menu-options">
                  <Tooltip title="Close PDF">
                    <Close alt="Close PDF" className="ai-pdf-settings close" onClick={closeBlob} />
                  </Tooltip>
                  <Tooltip title="Download PDF">
                    <DownloadIcon alt="Download PDF" className="ai-pdf-settings download" onClick={downloadPDF} />
                  </Tooltip>
                  <Tooltip title="Previous Page">
                    <ArrowLeft alt="Previous Page" className="ai-pdf-settings left" onClick={goToPreviousPage} />
                  </Tooltip>
                  <Tooltip title="Next Page">
                    <ArrowRight alt="Next Page" className="ai-pdf-settings right" onClick={goToNextPage} />
                  </Tooltip>
                  <div className="ai-pdf-page-counter">
                    <span>{pageNumber}</span>
                    <span> / {numPages}</span>
                  </div>
                </div>
              </nav>
              <div className="ai-pdf-viewer">
                <Document
                  file={blobAzure}
                  onLoadSuccess={onDocumentLoadSuccess}
                  options={options}
                  renderMode="canvas"
                >
                  <Page
                    className=""
                    key={pageNumber}
                    pageNumber={pageNumber}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    width={
                      containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                    }
                  />
                </Document>
              </div>
            </div>
          </> :
          <div className="loading-container" ref={divRef}>
            <div class="box">
              <div class="column"></div>
              <div class="bead-container">
                <div class="rod" id="rod-one">
                  <div class="bead"></div>
                  <div class="bead"></div>
                  <div class="bead"></div>
                </div>
                <div class="rod" id="rod-two">
                  <div class="bead"></div>
                  <div class="bead"></div>
                </div>
                <div class="rod" id="rod-three">
                  <div class="bead"></div>
                </div>
              </div>
              <div class="column"></div>
            </div>
            <h1 className="loading-heading">Loading...</h1>
          </div>
        }
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginScreen />
      </UnauthenticatedTemplate>
    </>
  );
}